/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.p-overlaypanel {
  border-radius: 10px !important;
}

.p-dialog {
  border-radius: 10px !important;

  .p-dialog-header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .p-dialog-content {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

.p-confirm-dialog {
  .p-dialog-content {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .p-dialog-footer {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

.data-view-container {
  .p-card {
    border-radius: 15px;
  }
}

#home .panel-category {
  .panel-category-title {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .p-listbox {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  &:first-of-type {
    border-top-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
  }
}
.p-datatable .p-datatable-tfoot > tr > td {
  &:first-of-type {
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-bottom-right-radius: 10px;
  }
}