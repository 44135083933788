/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.#{$rt-namespace}__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  //width: var(--toastify-toast-width);
  max-width: var(--toastify-toast-max-width);
  box-sizing: border-box;
  color: #fff;
  &--top-left {
    top: var(--toastify-toast-top);
    left: var(--toastify-toast-left);
  }
  &--top-center {
    top: var(--toastify-toast-top);
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: var(--toastify-toast-top);
    right: var(--toastify-toast-right);
  }
  &--bottom-left {
    bottom: var(--toastify-toast-bottom);
    left: var(--toastify-toast-left);
  }
  &--bottom-center {
    bottom: var(--toastify-toast-bottom);
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: var(--toastify-toast-bottom);
    right: var(--toastify-toast-right);
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);;
    margin: 0;
    &--top-left,
    &--top-center,
    &--top-right {
      top: env(safe-area-inset-top);
      transform: translateX(0);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: env(safe-area-inset-bottom);
      transform: translateX(0);
    }
    &--rtl {
      right: env(safe-area-inset-right);
      left: initial;
    }
  }
}
