/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.grid-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows:
            min-content
            min-content
            auto;
  grid-template-areas:
            "header"
            "main-tabs"
            "main-tabs-contents";
  height: 100vh;
  //overflow: hidden;

  .grid-area-header {
    grid-area: header;
  }

  .grid-area-main-tabs {
    grid-area: main-tabs;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: contain auto;
  }

  .grid-area-main-tabs-contents {
    grid-area: main-tabs-contents;
    overflow: hidden auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: column;

    &.no-footer {
      margin-bottom: 0;
    }
  }

}


.expand-vertically {
  height: 100%;
  width: 100%;
}

.before-expand-vertically-horizontally {
  width: 100%;
  height: 100%;
  //transition: width 2s;
  //transition-timing-function: ease;
}

.expand-vertically-horizontally {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  //transition: width 2s;
  //transition-timing-function: ease;
}
