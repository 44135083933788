/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

@mixin transform {
  transform: translate3d(0, var(--y), 0);
}

@keyframes #{$rt-namespace}__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes #{$rt-namespace}__slideOutRight {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes #{$rt-namespace}__slideOutLeft {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes #{$rt-namespace}__slideOutDown {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes #{$rt-namespace}__slideOutUp {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

@mixin timing {
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.#{$rt-namespace}__slide-enter {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__slideInLeft;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__slideInRight;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__slideInDown;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__slideInUp;
  }
}

.#{$rt-namespace}__slide-exit {
  &--top-left,
  &--bottom-left {
    animation-name: #{$rt-namespace}__slideOutLeft;
    @include timing;
  }
  &--top-right,
  &--bottom-right {
    animation-name: #{$rt-namespace}__slideOutRight;
    @include timing;
  }
  &--top-center {
    animation-name: #{$rt-namespace}__slideOutUp;
    @include timing;
  }
  &--bottom-center {
    animation-name: #{$rt-namespace}__slideOutDown;
    @include timing;
  }
}
