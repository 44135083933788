/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

$link-hover-decoration: none;

$surface-a: var(--surface-a);
$surface-b: var(--surface-b);
$surface-c: var(--surface-c);
$surface-d: var(--surface-d);
$surface-e: var(--surface-e);
$surface-f: var(--surface-f);
$text-color: var(--text-color);
$text-color_secondary: var(--text-color-secondary);
$primary-color: var(--primary-color);
$primary-color-text: var(--primary-color);
$font-family: var(--font-family);

$xl: 1280px;

