/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

html, body, #root {
  letter-spacing: .02rem;
  margin: 0;
  padding: 0;
  background-color: $surface-b;
  color: $text-color;
  font-family: $font-family;
  height: 100%;
  width: 100vw;
  max-width: 100vw;
}

*, :active, :focus {
  outline: 0;
}

.centered-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.centered-loader, .centered-no-data, .centered-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  i {
    font-size: 2em;
    margin-right: 1rem;
  }

  span {
    display: block;
    font-size: 1.5em;
  }
}

.centered-no-data, .centered-error {
  opacity: 0.6;
}

.centered-error i {
  color: red;
}

.clickable-icon {
  cursor: pointer;
  color: $text-color_secondary;
  margin-left: 0.5rem;
  margin-right: -0.5rem;
}

.disabled {
  opacity: 0.6;
}

.first-letter-uppercase {
  display: inline-block
}

.first-letter-uppercase:first-letter {
  text-transform: uppercase
}

.help-link {
  color: var(--primary-color);
  text-transform: none;
  text-decoration: none;
}

.hidden {
  display: none;
}

.homelink {
  color: $text-color;
  text-transform: none;
  text-decoration: none;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.p-button-icon-small {
  width: auto !important;
  padding: $spacer*0.25 $spacer*0.5 !important;

  .pi {
    font-size: 0.8rem;
  }
}

.p-button-shortcut {
  background-color: var(--surface-d) !important;
}

.p-button-shortcut:hover {
  background-color: var(--surface-d) !important;
  cursor: default !important;
}

.p-inline-message-error {
  width: 100% !important;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.speeddial-button > button {
  width: 2rem !important;
  height: 2rem !important;
}

.speeddial-button > ul > li > a {
  width: 2.5rem !important;
  height: 2.5rem !important;
}