/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

// issue : https://github.com/securingsincity/react-ace/issues/836
.ace_hidpi .ace_text-layer, .ace_hidpi .ace_gutter-layer, .ace_hidpi .ace_content, .ace_hidpi .ace_gutter {
  will-change: auto !important;
}

.ace_editor {
  position: absolute;
}

.custom-editor {
  position: relative;
  height: 100%;
  min-height: 230px;
  border-radius: 10px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom-editor-invalid {
  outline: none !important;
  border: 1px solid #ef9a9a;
}

.custom-editor-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding: $spacer;
  opacity: 50%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  z-index: 1000;

  .notification {
    //position: absolute;
    //bottom: 1rem;
    //right: 1rem;
    margin-top: 0.5rem;
    color: white;
  }
}

.custom-editor-actions:hover {
  opacity: 100%;
}