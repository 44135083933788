/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.no-header .p-datatable-thead {
  display: none;
}

.p-datatable {
  //overflow: auto;
  height: 100%;

  &.sticky-header thead {
    position: sticky;
    top: 0;
  }

  &.sticky-footer tfoot {
    position: sticky;
    bottom: 0;
  }

  table {
    width: 100%;
    border-spacing: 2px 0;

    thead tr, tfoot tr {
      .th-paginator {
        background-color: transparent;
        padding: 0!important;
      }

      td {
        .timing-header {
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
        }
      }
    }

    tbody {
      tr {
        td {
          //white-space:nowrap;
          //padding: 0.75rem 1rem !important;


          button.report-link, a.report-link, .collapsible-content button {
            position: unset; // avoid the button to be displayed above the header
          }

          .collapsible-content {
            width: 150px;
            white-space: nowrap;
            display: flex;
            flex-flow: row;
            align-items: center;

            button {
              width: auto !important;
              margin-right: calc($spacer / 2);
              flex-shrink: 0;

              &:hover {
                background: transparent !important;
              }
            }

            .content {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover td {
          background-color: var(--surface-b);
        }
      }
    }
  }

  &.fit-content table {
    width: auto !important;
    min-width: unset !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-overlaypanel.overlay-table-collapsible-content {
  max-width: 80%;
}