/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

$enable-responsive-font-sizes: true;
$enable-prefers-reduced-motion-media-query: false;


@import 'toastify/main';

@import "~primeflex/core/variables";
@import "variables";

@import "general";

@import "~primeflex/primeflex";
@import "~primereact/resources/primereact.css";
@import "~primeicons/primeicons.css";
@import "primereact_override";

@import "icons";
@import "layout_help";
@import "layout_home";
@import "layout_main";
@import "layout_parameter_inputs";
@import "layout_report_dev";
@import "layout_sql_console";
@import "header";
@import "header-tabs";
@import "contextual-menu";
@import "divider";
@import "menu";
@import "form";
@import "login";
@import "settings";
@import "react-ace";
@import "text-colors";
@import "layout-data-view-grid";
@import "notification";
@import "dialog";
@import "button";
@import "tooltip";
@import "table";
@import "debug";

@import 'data-view';

@import "footer";

// components override
@import "tree-node";

@import "border-radius";

@import "animation";
