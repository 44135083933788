/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.reports-container {
  flex-grow: 1;

  h1 {
    text-align: center;

    .p-button {
      margin-left: $spacer * 1.5;

      .pi {
        font-size: 1.3rem;
      }
    }
  }

  [id~=sql_editor_query_init_], [id~=sql_editor_query_cleanup_] {
    width: inherit;
  }

  .grid-report-content-dev {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows:
      min-content
      auto
      min-content;
    grid-gap: 0;
    grid-template-areas:
      "report-query-init"
      "report-data-views"
      "report-query-cleanup";
    height: 100%;

    .grid-report-area-query-init {
      grid-area: report-query-init;
      position: relative;
    }

    .grid-report-area-data-views {
      grid-area: report-data-views;
    }

    .grid-report-area-query-cleanup {
      grid-area: report-query-cleanup;
      position: relative;
    }
  }
}

.data-view-message-error {
  margin: 1rem !important;
  background-color: var(--surface-a) !important;
  color: var(--text-color) !important;
  border-width: 1px !important;
}