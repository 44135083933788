/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.p-dialog {
  width: 90vw;
  @media screen and (min-width: $md) {
    width: 70vw;
  }
  @media screen and (min-width: $lg) {
    width: 50vw;
  }

  &.settings-dialog.p-dialog-maximized {
    margin: 0.75rem !important;
    height: calc(100vh - (0.75rem * 2)) !important;
  }

  &.custom-editor-fullscreen {
    .p-dialog-content {
      padding: 0.25rem;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.p-overlaypanel {
  max-width: 30rem;
}

//.p-dialog-maximized {
  //height: calc(100vh - .75rem*2) !important;
//}

.p-dialog#global-dialog {
  margin-top: .5rem;
  margin-bottom: .5rem;
  max-height: calc(100vh - 1rem);
}