/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.subgrid-area-data-view-params {

  .field {
    margin-bottom: 0.2rem;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0 !important;
  }

  .p-accordion-content {
    cursor: default;
  }

  .p-accordion-tab:hover {
    cursor: grabbing !important;
  }

  h3 {
    margin: 0.2rem;
  }

  h4 {
    margin: 0.1rem;
    font-weight: normal;
  }
  //h4:hover {
  //  background-color: var(--surface-d);
  //  background-blend-mode: darken;
  //}
}