/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

.ace_editor.ace_autocomplete {
  width: 600px !important;
}

.sql-console-splitter-container {
  overflow: hidden;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  border: none;
  background: none;

  #sql-console-tree-db-container {
    overflow: hidden;
    display: flex;
    flex-flow: row;
    width: 450px;
    min-width: 450px;
    height: 100%;
    max-height: 100%;
    margin-right: 0.5rem;
    background-color: var(--surface-a);
    border: 1px solid var(--surface-d);
    border-bottom-right-radius: 15px !important;
    border-top-right-radius: 15px !important;

    .splitter-sql-console-tree-db {
      margin: 1rem;
      padding: 0;
      display: flex;
      flex-flow: column;
      overflow: auto;
      flex-grow: 1;

      .p-tree {
        flex-grow: 1;
        overflow: auto;
        background: none;
        border: none;
      }
    }

    &.hide-tree {
      width: auto !important;
      min-width: auto !important;

      .splitter-sql-console-tree-db {
        display: none;
      }
    }

    &-toggle {
      width: 16px !important;
      border: none;

      &:not(:hover) {
        background: transparent;
      }
    }
  }

  #sql-console-console-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    max-width: 100%;
    margin-right: 0.5rem;
    overflow: hidden;
  }

  .p-tabview {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    max-width: 100%;
    max-height: 100%;

    .p-tabview-nav {
      border-radius: 10px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-width: 1px;

      li {
        &:first-child .p-tabview-nav-link {
          border-radius: 10px !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
        &:last-child .p-tabview-nav-link {
          border-radius: 10px !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }
      }
    }
  }

  .p-tabview-panels {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    padding: 0;
    flex-grow: 1;
    //background-color: #272822;
    //color: #F8F8F2;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .p-tabview-panel {
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .before-expand-vertically-horizontally {
      width: unset;
      height: unset;
    }

    .custom-editor, .textarea-resize-handler {
      border-radius: 0 !important
    }

    .sql-console-results {
      padding-right: 1rem;
      padding-left: 1rem;
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      //background-color: #272822;
      //color: #F8F8F2;
      overflow: auto;
    }
  }

  //.p-paginator {
  //  background: none;
  //  color: inherit;
  //
  //  .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
  //    color: inherit;
  //
  //    &:hover {
  //      color: inherit;
  //      background: #202020;
  //    }
  //  }
  //
  //  .p-paginator-pages .p-paginator-page.p-highlight {
  //    color: inherit;
  //    background: #202020;
  //    font-weight: bolder;
  //  }
  //
  //  .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  //    background: #202020;
  //    color: inherit;
  //  }
  //
  //  .p-inputnumber {
  //    background: none;
  //    color: inherit;
  //
  //    .p-inputnumber-button-up, .p-inputnumber-button-down {
  //      background: none;
  //      border-color: #ced4da;
  //    }
  //
  //    .p-inputtext {
  //      color: inherit;
  //      background: none;
  //      border-color: #ced4da;
  //    }
  //  }
  //}

  //table {
  //  border: none !important;
  //  color: inherit !important;
  //
  //  //tr, th, td {
  //    //background: #272822 !important;
  //    //color: inherit !important;
  //  //}
  //
  //  th, td {
  //    border: none !important;
  //    padding: 0.5rem !important;
  //  }
  //
  //  //th {
  //  //  border-bottom: 1px solid #F8F8F2 !important;
  //  //}
  //  //
  //  //tr:hover {
  //  //  background: #202020 !important;
  //  //}
  //}


  .tree-db {
    border: none;
    margin-top: 0.25rem;
    padding: 0 !important;
    overflow: auto;
    flex-grow: 1;
  }
}