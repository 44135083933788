/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */

@keyframes #{$rt-namespace}__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.#{$rt-namespace}__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);

  &--animated {
    animation: #{$rt-namespace}__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform 0.2s;
  }

  &--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: var(--toastify-toast-bd-radius);
  }

  &--wrp {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border-bottom-left-radius: var(--toastify-toast-bd-radius);
  }

  &--wrp[data-hidden="true"] {
    opacity: 0;
  }

  &--bg {
    opacity: var(--toastify-color-progress-bgo);
    width: 100%;
    height: 100%;
  }
}
