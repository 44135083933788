/*!
 * This file is part of RootDB.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * AUTHORS
 * PORQUET Sébastien <sebastien.porquet@ijaz.fr>
 * ROBIN Brice <brice@robri.net>
 */


.autocomplete-spinner > i {
  font-size: 1rem;
}

.pi-spin {
  color: grey;
}

.p-spinner {
  color: grey;
}

.p-colorpicker-preview {
  width: 100% !important;
  height: inherit !important;
}

#report-parameters {
  label {
    font-size: 0.8rem;
  }

  .field-radiobutton > label {
    font-size: inherit;
  }
}

///**
// * To have the first columns of InputGroup.Append components inside an InputGroup component with the same width.
// * To use with InputGroup component. ( className="flw-10/15/20...100" )
// */
//@for $i from 5 through 100 {
//  @if ($i%5 == 0) {
//    form > div > .flw-#{$i} > div:first-child {
//      // https://youtrack.jetbrains.com/issue/WEB-20693
//      min-width: #{$i}% !important;
//    }
//  }
//}
//
//div[class*="flw"] > div > span {
//  width: 100% !important;
//}

.textarea-resize-handler {
  height: 10px;
  cursor: row-resize;
  border: 1px solid $text-color_secondary;


  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-chips-multiple-container {
  flex-grow: 1;
}

.icon-draggable {
  cursor: grab;
  padding-bottom: 0.4rem !important;
}